#summary-section {
  width: calc(100% - 1em);
  padding-left: 1em;
}

.details-section-tabs-pivot {
  .ms-DetailsRow-fields {
    font-size: 14px;
  }
}

.table {
  margin-bottom: 0 !important;
}

.summary-section-search-box {
  max-width: 150px !important;
  min-width: 50px;
  width: 100%;
  height: 30px;
}

.summary-section-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
  row-gap: 1em;
  height: calc(100% - 1em);
}

.summary-section-title {
  font-weight: 600;
  padding-left: 1em;
  padding-right: 0.5em;
}

.summary-section-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: white;
  padding: 0px;
  flex-grow: 1;
}

.summary-section-details {
  height: 100%;

  .summary-section-content {
    height: calc(100% - 32px - 1em);
    overflow-y: unset;

    .details-section-tabs-container {
      width: 100%;
      height: calc(100% - 48px);

      .pagination-items {
        height: 100%;
        overflow: auto;
      }

      .table-root {
        height: 100%;

        .data-grid-wrapper {
          height: 100%;
          overflow-y: hidden;

          .fui-DataGrid {
            height: 100%;

            .fui-DataGridBody {
              height: calc(100% - 42px);
              overflow-y: auto;
            }
          }
        }
      }
    }
  }
}

.slick-list {
  height: 100%;
}

.slick-slide div {
  padding-top: 1em;
}

button.slick-prev.slick-arrow,
button.slick-next.slick-arrow {
  position: absolute;
  background-image: none;
  background-color: rgba(255, 255, 255, 0.75);
  top: calc(100% - 20px);
  z-index: 1;

  :hover {
    background-color: rgba(243, 242, 241, 1);
  }

  :active {
    background-color: rgba(237, 235, 233, 1);
  }
}

button.slick-prev.slick-arrow {
  left: 35%;
}

button.slick-next.slick-arrow {
  right: 35%;
}

.slick-slider-image {
  box-sizing: border-box;
  object-fit: contain;
  cursor: pointer;
  max-height: 100%;
  padding: 1em;
}

.condition-content-badge-container {
  box-sizing: border-box;
  padding: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: space-between;
  gap: 4px;

  span {
    font-weight: 600;
  }
}

.disable-unchecked-values {
  .ms-DetailsRow:not(.is-selected) {
    pointer-events: none;
    .ms-DetailsRow-fields,
    .ms-DetailsRow-cellCheck {
      opacity: 0.5;
    }
  }
}

.disable-select-all-button {
  .ms-DetailsHeader-cellIsCheck {
    pointer-events: none;
    opacity: 0;
  }
}

.ms-DetailsRow-check {
  width: 18px;
  .ms-Check,
  i.ms-Check-circle,
  i.ms-Check-check {
    scale: 0.9;
  }
}

.summary-section-drawings,
.summary-section-conditions {
  .summary-section-content {
    height: 30em;
  }
}

@media only screen and (max-width: 1100px) {
  .summary-section-container {
    width: calc(100% - 2em);
    max-width: calc(100% - 2em);
  }

  .summary-section-drawings {
    max-height: 30em;
  }

  .slick-slider-image {
    height: calc(26em - 20px);
  }

  .summary-section-signals {
    max-height: 30em;
  }

  .summary-section-details {
    max-height: 100%;
  }
}

@media only screen and (min-width: 1100px) {
  #summary-section {
    min-height: calc(100vh - 84px);
  }

  .summary-section-drawings,
  .summary-section-conditions {
    width: calc(50% - 2em);
    max-width: calc(50vw - 2em);
  }

  .summary-section-signals {
    width: 35%;
  }

  .summary-section-details {
    max-width: calc(65% - 0.5em);
  }

  .summary-section-conditions,
  .summary-section-details {
    flex-grow: 1;
  }
}
