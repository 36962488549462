$barWidth: 54px;

.icb {
  display: flex;
  user-select: none;
  flex: 1;
  position: relative;

  &__values {
    margin-left: 9px;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    color: #3b3a39;
    font-size: 10px;
  }

  &__value {
    padding: 2px 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: -9px;
      width: 7px;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background-color: #f3f2f1;
    }
  }

  &__vertical-bar {
    border-radius: 2px;
    border: 1px solid #f3f2f1;
    box-sizing: content-box;
    position: relative;
    background: linear-gradient(#2c529f, #fff);
    width: 54px;
    max-width: 54px;
  }

  &__tooltip {
    background: #fff;
    box-shadow:
      0 0.3px 0.9px rgba(0, 0, 0, 0.1),
      0 1.6px 3.6px rgba(0, 0, 0, 0.13);
    border-radius: 2px;
    padding: 4px 8px;
    font-size: 12px;
    position: absolute;
    z-index: 100;
    color: red;
    right: calc(100% + 5px);
    top: 0;
  }
}

.selection-area {
  display: none !important;
}

.selection-container {
  user-select: none;
  width: 100%;
  height: 100%;
}

.icb-container {
  display: flex;
  flex-direction: column;
}
