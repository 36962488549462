.table {
  margin: 16px 0;
  background: #fff;

  &--has-header {
    margin-top: 0;
  }
}

.pagination {
  margin-top: 16px;
}

.table.table--loading {
  margin-bottom: 12px;
}

.table .ms-FocusZone {
  padding-top: 0;
}

.table-header {
  background: #f3f2f1;
  display: flex;
  padding: 0;
  margin-top: 16px;

  &__column {
    width: 100%;
  }
}

.table-per-page {
  display: flex;
  margin-left: auto !important;
  align-items: center;

  > span {
    margin-right: 10px;
  }
}
