.info-machine-details {
  height: 100%;

  .info-table {
    margin: 0;
    height: calc(100% - 42px);

    .data-grid-wrapper {
      position: relative;
      height: calc(100% - 48px);
      overflow-y: hidden;
    }

    .fui-DataGrid {
      height: 100%;

      .fui-DataGridBody {
        height: calc(100% - 40px);
        max-height: 33vh;
        overflow-y: auto;
      }
    }
  }
}
