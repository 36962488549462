.text-field-with-documents {
  .attachmentButton {
    color: "#2C529F";
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    :hover {
      background-color: #f3f2f1;
    }
  }

  .filesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    padding: 20px;
    max-height: 60vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .fileItem {
    border: 1px solid #edebe9;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    background-color: #ffffff;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    :hover {
      background-color: #f8f8f8;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px);
    }
  }
}
