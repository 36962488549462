.torque-header {
  display: flex;
  align-items: center;
  min-height: 36px;

  .left {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .right {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .semibold-title {
    margin-right: 16px;
  }
}

.torque-am-chart {
  position: relative;
  height: 100%;
  .loading-chart-data {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.torque-am-chart-maximized {
  position: relative;
  height: 80vh;
  .loading-chart-data {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
