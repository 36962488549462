@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-VariableFont_wdth\,wght.ttf") format("truetype");
  font-weight: 100 900; // Define weight range (e.g., 100 to 900)
  font-style: normal;
  font-display: swap; // Ensures text is visible while the font loads
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-Italic-VariableFont_wdth\,wght.ttf") format("truetype");
  font-weight: 100 900; // Define weight range (e.g., 100 to 900)
  font-style: italic;
  font-display: swap; // Ensures text is visible while the font loads
}
