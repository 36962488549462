.harmonics-catalogue-tab-menu {
  height: 100%;

  > :nth-child(2) {
    height: calc(100% - 60px);
    overflow: auto;

    > div {
      height: 100%;
    }
  }

  button:hover {
    &:not(.is-selected) {
      color: rgb(32, 31, 30);
      background: rgb(243, 242, 241);
    }

    background-color: #2c529f;
    color: #fff;
  }
}

.catalogue-content {
  > .ms-Viewport {
    flex: 1;
    min-height: 300px;

    // > .ms-DetailsList {
    //   height: 100%;
    //   overflow: hidden;
    //   margin: 0;

    //   > div {
    //     height: 100%;
    //     overflow: auto;
    //   }

    //   .ms-DetailsList-headerWrapper {
    //     position: sticky;
    //     top: 0;
    //     z-index: 1;
    //   }

    //   .ms-GroupHeader {
    //     background: #f3f2f1;
    //     pointer-events: none;

    //     .ms-GroupHeader-title {
    //       font-size: 14px;
    //     }
    //   }
    // }
  }

  // .disable-unchecked-values {
  //   .ms-DetailsRow:not(.is-selected) {
  //     pointer-events: none;
  //     .ms-DetailsRow-fields,
  //     .ms-DetailsRow-cellCheck {
  //       opacity: 0.5;
  //     }
  //   }
  // }

  // .disable-select-all-button {
  //   .ms-FocusZone {
  //     .ms-DetailsHeader-cell:nth-child(2) {
  //       display: none;
  //     }
  //   }
  //   .ms-GroupHeader-expand,
  //   .ms-GroupSpacer {
  //     display: none !important;
  //   }
  //   .ms-DetailsHeader-cellIsCheck {
  //     pointer-events: none;
  //     opacity: 0;
  //   }
  // }
}
