* {
  --text-black: rgb(50, 49, 48);
}

.section-title {
  color: var(--text-black);
  font-size: 16px;
  font-weight: 600;
}

.label-property-text {
  color: var(--text-black);
  font-size: 14px;
  font-weight: 400;
}

.label-value-text {
  color: var(--text-black);
  font-size: 14px;
  font-weight: 600;
  justify-self: center;
}

.normal-text {
  font-size: 14px;
  font-weight: 400;
}

.table-item-text {
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.table-button-text {
  color: var(--dalog-blue);
  font-size: 12px;
}
