@import "./main.scss";

@import "../../../node_modules/mapbox-gl/dist/mapbox-gl";

@import "../../Components/common/Table/v9/Table.scss";
@import "../../Components/common/Notification/Notification.scss";
@import "../../Components/common/XYChart/XYChart.scss";
@import "../../Components/common/PowerBi/PowerBi.scss";
@import "../../Components/common/Map/Map.scss";

@import "../../Components/common/Collapse/style.scss";

@import "../../modules/common/Components/SignalsAutocomplete/SignalsAutocomplete.scss";
@import "../../modules/common/Components/Dashboards/DashboardCard.scss";

@import "../../modules/analysis-trend-view/Components/TrendViewSelectSignals/TrendViewSelectSignals.scss";
@import "../../modules/analysis-trend-view/Components/TrendViewSection/TrendViewSection.scss";
@import "../../modules/analysis-trend-view/Components/TrendViewPlot/TrendViewPlot.scss";
@import "../../modules/analysis-trend-view/Components/TrendViewGroupedSection/TrendViewGroupedSection.scss";

@import "../../modules/analysis-raw-data/Components/Placeholder/Placeholder.scss";
@import "../../modules/analysis-raw-data/Components/CheckboxTree/CheckboxTree.scss";
@import "../../modules/analysis-raw-data/Components/HeaderControls/HeaderControls.scss";
@import "../../modules/analysis-raw-data/Components/SignalsList/SignalsList.scss";

@import "../../modules/analysis-raw-data/Components/ContextMenu/ContextMenu.scss";

@import "../../Components/Analysis/RawDataPage/RawDataPage.scss";

@import "../../Components/WirelessSensors/style.scss";
@import "../../Components/MachineCV/MachineCVSummaryPage/style.scss";
@import "../../Components/MachineCV/MachineCVDriveLoadPage/MachineCVDriveLoadPage.scss";
@import "../../Components/MachineCV/MachineCVPerformancePage/style.scss";

* {
  margin: 0px;
  padding: 0px;
}
