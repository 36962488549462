.ws-content-wrapper {
  padding: 5px;
  height: calc(100vh - 62px);
  width: 100%;
  overflow: auto;
  transition: all 0.2s ease;

  .chart-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px 0;

    .trigger-button {
      align-self: center;
      margin-top: 10px;
    }

    .chart-inner-container {
      height: 85vh;

      &.loading {
        opacity: 0.3;
      }

      .orgchart {
        background-image: none;
        border: 0;
        padding: 0;
      }

      .orgchart-container {
        border: 0;
        margin: 0 auto;
        padding: 100px 30px;
        height: 100%;
      }

      .orgchart ul li .oc-node:not(:only-child)::after {
        width: 1px;
        background-color: #2c529f;
      }

      .orgchart > ul > li > ul li > .oc-node::before {
        top: -12px;
        width: 1px;
        background-color: #2c529f;
      }

      .orgchart > ul > li > ul li::before {
        border-top: 1px solid #2c529f;
      }

      .orgchart ul li .oc-node.selected,
      .orgchart ul li .oc-node:hover {
        background-color: white;
      }
    }

    .tree-node-container {
      display: flex;
      border: 1px solid #2c529f;
      justify-content: center;
      align-items: center;
      height: 80px;
      max-width: 150px;
      min-width: 80px;
      padding: 5px;
      position: relative;

      // &:hover {
      //   cursor: pointer;
      // }

      .name {
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 15px;
      }

      .icons-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .condition {
          padding: 6px;
          border-radius: 999px;
          margin-left: 10px;
        }
      }

      .node-tooltip-container {
        position: absolute;
        display: flex;
        border: 1px solid #2c529f;
        justify-content: space-between;
        align-items: center;
        min-height: 130px;
        max-width: 350px;
        min-width: 250px;
        padding: 10px;
        background: white;
        z-index: 9;
        top: -2px;
        left: -2px;

        // &:hover {
        //   cursor: initial
        // }

        .icon {
          margin: 0 10px;

          svg {
            min-width: 40px;
            height: auto;
          }
        }

        .content-container {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          min-width: 200px;

          .content-line {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 3px 0;

            .content-span {
              font-weight: 600;
              margin-left: 5px;
              align-items: center;
              display: flex;
              width: 100px;
            }

            .condition {
              padding: 10px;
              border-radius: 999px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .sensor-triggering-container {
    height: 100%;
    background: white;
    padding: 10px;
    overflow: scroll;

    .triggering-configuration {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .time-form-container {
        display: flex;

        .form {
          width: 300px;
        }

        .save-button {
          margin-left: 10px;
          margin-top: 8px;
        }
      }
    }

    .triggering-statistics {
      display: flex;
      flex-direction: column;

      svg {
        width: 80%;
        height: auto;
        align-self: center;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 600;
      margin-top: 15px;
    }

    .description {
      font-size: 14px;
      opacity: 0.5;
      margin-top: 10px;
    }
  }
  .sensor-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;
    padding: 10px;
    position: relative;
    overflow: auto;

    .sensor-header {
      display: flex;
      align-items: center;

      .sensor-title {
        font-size: 18px;
        font-weight: 600;
        margin-right: 5px;
      }
    }

    .tab-wrapper {
      display: flex;
      flex-direction: column;
      padding: 20px;
      align-items: flex-start;

      .sensor-info-label {
        border-left: 5px solid #2c529f;
        background: #f3f2f1;
        margin: 10px 0 0 0;
        padding: 7px 15px;
        min-width: 400px;
      }

      .sensor-alert-label {
        border-left: 5px solid #ff7e0d;
        background: #f3f2f1;
        margin: 10px 0 0 0;
        padding: 7px 15px;
        min-width: 400px;
      }

      .tab-subtitle {
        font-size: 16px;
        font-weight: 600;
      }

      .manual-dropdown {
        display: flex;
        margin-top: 15px;
        align-items: center;
      }

      .triggers-collapse-wrapper {
        display: flex;
        margin-left: 35px;

        .triggers-input-container {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-right: 25px;

          .triggers-tooltip {
            display: flex;
            flex-direction: row;

            .triggers-tooltip-title {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }

      .settings-collapse-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 35px;

        .settings-input-container {
          display: flex;
          flex-direction: column;

          .settings-title-container {
            display: flex;
          }

          .settings-checkbox-container {
            display: flex;
            gap: 40px;
            margin: 10px;
          }
        }
      }
    }
  }

  .sensor-save-button {
    margin-top: 40px;
  }

  .radio-item {
    .radio-input {
      cursor: pointer;
      order: 0;
      margin-right: 8px;
      width: 20px;
      height: 20px;
      accent-color: rgb(44, 82, 159);
    }

    .radio-label {
      cursor: pointer;
      order: 1;
      color: rgb(50, 49, 48);
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.chart-header {
  .chart-header-title {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 15px;
    margin-right: 10px;
    position: relative;
    border-right: 1px solid $westar;
  }
}

.ws-sidebar-tree {
  .sidebar-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 7px 4px;

    &.selected {
      position: relative;
      background-color: #ecebe9;

      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 35px;
        background-color: #ecebe9;
      }

      &:after {
        left: 0;
        margin-left: -9px;
      }

      &:before {
        right: 0;
        margin-right: -9px;
      }
    }

    .sidebar-content {
      display: flex;
      width: 100%;
      max-width: calc(100% - 20px);

      .node-icon {
        display: flex;
        align-items: center;
        margin-right: 8px;
      }

      .node-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.ht_hyperTreeNodeWrapper {
  .ht_hyperNodeWrapper.ht_hyperNodeWrapper.ht_hyperNodeWrapper {
    padding: 0 9px;

    &:hover {
      background-color: $cararra !important;
    }
  }
  .ht_hyperNodeWrapper.ht_selected {
    background: none !important;
  }
}

.sensor-configurator {
  display: flex;
  flex-direction: column;
  flex: 1;

  .tab-wrapper,
  .tab-container,
  div[role="tabpanel"] {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .tab-wrapper {
    position: relative;
  }
}
