.machine-cv-navigation {
  a {
    white-space: nowrap;
  }
}

.machine-cv-navigation-menu {
  border: unset;
  background: transparent;
}

// styles for sticky breadcrumb work only in production environment
.breadcrumb-container {
  position: sticky;
  top: 0;
  z-index: 1;
  background: rgb(250, 249, 248);
}

@media only screen and (max-width: 1100px) {
  .machine-cv-navigation-bar {
    display: none;
  }
}

@media only screen and (min-width: 1100px) {
  .machine-cv-navigation-menu {
    display: none;
  }
}
