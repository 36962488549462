.ht_selected {
  background-color: transparent !important;
}

.tree-node {
  display: flex;
  align-items: center;
  min-height: 35px;
  max-height: 35px;
}

.tree-node .tree-icon {
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-repeat: no-repeat;
}

.tree-node .node-content-wrapper {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 8px;
  padding-left: 0px;
  // -webkit-transition: all 0.2s ease;
  // transition: all 0.2s ease;
}

// .tree-node .node-content-wrapper:hover {
//   background-color: #f5f5f5;
// }

// .tree-node.is-leaf .node-content-wrapper:hover {
//   background-color: #f5f5f5;
// }

.tree-node .node-content-wrapper.node-selected {
  border-left: 4px solid #d72638;
}

.tree-node .node-content-wrapper .titles {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tree-node .node-content-wrapper .titles .node-title {
  font-weight: normal;
  font-size: 14px;
}

.tree-node .node-content-wrapper .titles .node-subtitle {
  margin-top: 4px;
  font-size: 10px;
}
