.trend-view-section {
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  padding: 16px;
  background: #fff;
  margin-bottom: 1rem;

  .chart-container {
    transition: opacity .3s ease-in-out;
  }

  &.loading {

    .chart-container {
      opacity: .3;
      pointer-events: none;
      user-select: none;
    }
  }
}

.trend-view-section .amcharts-amexport-menu.amcharts-amexport-menu-level-0 {
  top: -35px !important;
}

.trend-view-section .ms-DetailsHeader {
  padding-top: 0;
  background: #faf9f8 !important;
}

.trend-view-section .ms-DetailsRow {
  background: #faf9f8 !important;
}

.modal-chart-container {
  position: relative;

  .chart-container {
    transition: opacity .3s ease-in-out;
  }

  &.loading {

    .chart-container {
      opacity: .3;
      pointer-events: none;
      user-select: none;
    }
  }
}
