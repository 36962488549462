:root {
  --chart-symbol-color-blue: #2b88d8;
  --chart-symbol-color-red: #cc0500;
  --chart-symbol-color-pink: #ff00ff;
  --chart-symbol-color-green: #afe085;
  --chart-symbol-color-yellow: #d69100;
  --chart-symbol-color-purple: #8b4b8b;
  --chart-symbol-color-lime: #32cd32;
  --chart-symbol-color-cyan: #00ced1;
}
