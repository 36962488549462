.vertical-tabs {
  display: flex;
  height: calc(100vh - 50px);
}

.settings-tabs {
  display: flex;
  flex-direction: column;
  width: 240px;
}

.settings-tab {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  background-color: #faf9f8;
  border-bottom: none;
  user-select: none;
}

.settings-tab.active {
  background-color: #c7e0f4;
}

.settings-tab.active * {
  color: #2c529f;
}

.settings-tab:hover {
  background-color: #c7e0f480;
}

.settings-tab:hover * {
  color: #2c529f;
}

.settings-tab-content {
  flex: 3;
  border-left: 1px solid #f3f2f1;
  border-top: none;
  min-height: 150px;
  background-color: #fff;
  overflow: hidden;
  height: 100%;
}

.settings-tab-pane {
  display: none;
}

.settings-tab-pane.active {
  display: block;
  height: 100%;
}

.settings-tab-pane-content {
  height: 100%;
  position: relative;
}

.settings-nested-tabs {
  padding: 0;
  background-color: transparent;
}

.settings-nested-tabs .settings-tabs {
  padding-top: 16px;
  padding-bottom: 16px;
}

.settings-header-content {
  padding: 20px;
}

.settings-tabs-header {
  font-size: 20px;
  font-weight: 600;
}
