.chart-layout-modal_setting {
  &--content {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.input-description {
  color: rgb(59, 58, 57);
  font-size: 12px;
  line-height: 16px;
}
