.performance-status {
  position: relative;
  display: flex;
  height: 36px;
  border-radius: 2px;
  overflow: hidden;

  &__step {
    flex: 1;
  }

  &__indicator {
    width: 0;
    height: 8px;
    position: absolute;
    left: 0;
    top: calc(50% - 4px);
    border-radius: 0 2px 2px 0;
    background: #2C529F;
  }
}
