.signals-section {
  height: 100%;

  .summary-section-content {
    height: calc(100% - 32px - 1em);
    overflow: unset;

    .signals-section-table {
      height: 100%;

      .data-grid-wrapper {
        height: 100%;
        overflow-y: hidden;

        .fui-DataGrid {
          height: 100%;

          .fui-DataGridBody {
            height: calc(100% - 42px);
            overflow-y: auto;
          }
        }
      }
    }
  }
}
