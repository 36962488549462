$white: #fff;
$mine_shaft: #333333;
$alizarin: #d72638;
$lochmara: #0078d4;
$link_water: #c7e0f4;
$transparent: transparent;
$denim: #106ebe;

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ht_selected {
  background-color: $transparent !important;
}
.tree-node {
  display: flex;
  align-items: center;
  min-height: 35px;
  max-height: 35px;
  .tree-icon {
    margin-right: 8px;
    border-radius: 50%;
    cursor: pointer;
    background-repeat: no-repeat;
  }
  .node-content-wrapper {
    min-height: 35px;
    max-height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 6px;

    .titles {
      cursor: pointer;
      display: flex;
      align-items: center;
      .node-title {
        font-weight: 400;
        font-size: 14px;
      }
      .node-subtitle {
        margin-top: 4px;
        font-size: 10px;
      }
    }
    .children-length {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 24px;
      height: 16px;
      background-color: $denim;
      color: $white;
      padding: 4px;
      font-size: 12px;
      border-radius: 16px;
      margin-left: 12px;
      font-weight: 400;
    }
  }
  .node-content-wrapper.node-selected {
    border-left: 4px solid $alizarin;
  }
}

.amcharts-amexport-menu {
  top: 0 !important;
  right: 0 !important;
}
.loader {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin: 0 auto 10px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.5px solid $lochmara;
  border-right: 1.5px solid $lochmara;
  border-bottom: 1.5px solid $lochmara;
  border-left: 1.5px solid $link_water;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  &:after {
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
}
.loader-label {
  font-size: 14px;
  color: $mine_shaft;
  text-align: center;
  font-weight: 600;
}
.loader-sublabel {
  font-size: 10px;
  color: $mine_shaft;
  text-align: center;
}
