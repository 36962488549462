.waterfall-chart-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }

  animation-name: fadeIn;
  animation-duration: 0.8s; 

  .js-plotly-plot {
    width: calc(100% - 100px) !important;

    .plot-container {
      .main-svg {
        .shapelayer .shape-group {
          .shape-label {
            transform: translateY(28px);
            // outline: solid 1px red;
            // outline-offset: 2px;
          }
        }
      }
    }
  }

  .icb-container {
    width: 92px;
  }
}
