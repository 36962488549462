.white-container {
  border-radius: 2px;
  background-color: #fff;
  padding: 8px;
}

.semibold-title {
  color: #3b3a39;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.mb-20 {
  margin-bottom: 15px;
}

.mb-8 {
  margin-bottom: 8px;
}

.h-100 {
  height: 100%;
}

.body-text {
  color: #3b3a39;
  font-size: 14px;
  line-height: 20px;
}

.status-box {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #aaa;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  &--normal {
    background-color: #66cd7d;
  }
}

.machine-cv-drive-load {
  padding: 13px 16px;

  .first-row {
    width: 100%;
    display: flex;
    gap: 16px;

    .load-and-performance {
      width: 30%;
      min-width: 150px;
      max-width: 260px;
      display: flex;
      flex-direction: column;

      .white-container {
        flex: 1;
      }

      .semibold-title {
        display: flex;
        align-items: center;
        height: 36px;
      }
    }

    .torque-and-drive-load {
      gap: 16px;
      display: flex;
      flex-direction: row;
      width: 100%;
      flex: 1;
    }
  }

  .second-row {
    margin-top: 15px;
    width: 100%;
    display: flex;
    gap: 16px;
  }
}

.torque-and-drive-load-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  flex: 1;
}

.torque-drive-load {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  .white-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.m-a {
  margin: auto;
}

.torque-chart {
  height: 100%;
  transition: all 0.3s ease;
}

.torque-chart--maximized {
  position: fixed;
  top: 50px; /* Ajusta según el tamaño de tu barra superior */
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: white;
  overflow: auto;
}

.chart-container {
  transition: all 0.3s ease;
}

.chart-maximized {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background: white;
}

.chart-container:not(.chart-maximized) {
  height: auto;
  width: 100%;
}

.torque-chart--maximized .chart-container:not(.chart-maximized) {
  display: none;
}

.events-table-container .table .ms-DetailsHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.events-table-container .table .ms-DetailsList-contentWrapper {
  overflow-y: auto;
  max-height: 1000px;
}

.events-table-container .table-drive-load-events {
  scrollbar-width: none;
}
