.default-stack-styles {
  display: flex;
  width: auto;
  box-sizing: border-box;
  * {
    text-overflow: ellipsis;
  }
}

.default-stack-item-styles {
  width: auto;
  height: auto;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;

  * {
    text-overflow: ellipsis;
  }
}
