.mcv-notification-fade-appear,
.mcv-notification-fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.mcv-notification-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.mcv-notification-fade-appear.mcv-notification-fade-appear-active,
.mcv-notification-fade-enter.mcv-notification-fade-enter-active {
  animation-name: fadein;
  animation-play-state: running;
}

.mcv-notification-fade-leave.mcv-notification-fade-leave-active {
  animation-name: fadeout;
  animation-play-state: running;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
