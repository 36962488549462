.machine-cv-tasks-table {
  padding: 0 16px;

  .ms-DetailsRow-fields {
    font-size: 14px;
  }

  .ms-DetailsList-headerWrapper {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  > .ms-Viewport > .ms-DetailsList {
    height: calc(50vh - 210px);
    background: #fff;
  }
}

.tasks-table__hide-select-all-checkmark {
  .ms-DetailsHeader-cellIsCheck {
    opacity: 0;
  }
}

.tasks-table-row {
  background: #fff;
  border-bottom: 1px solid rgb(243, 242, 241);

  &:hover {
    background: rgb(243, 242, 241);
    color: rgb(50, 49, 48);
  }

  .ms-DetailsRow-cellCheck {
    opacity: 0;
  }
}
