.form-time-picker {
  width: 100%;

  .rc-time-picker {
    width: 100%;

    .rc-time-picker-input {
      border-radius: 2px;
      padding: 0 8px;
      color: rgb(50, 49, 48);
      font-family:
        "Open Sans",
        "Segoe UI",
        "Segoe UI Web (West European)",
        "Segoe UI",
        -apple-system,
        BlinkMacSystemFont,
        Roboto,
        "Helvetica Neue",
        sans-serif;
      font-size: 14px;
      height: 32px;
      border: 1px solid rgb(96, 94, 92);
    }
  }

  &.error {
    .rc-time-picker {
      .rc-time-picker-input {
        border: 1px solid rgb(164, 38, 44);
      }
    }
  }

  .error {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
  }
}
