.section-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section-container-header {
  padding-top: 20px;
  padding-bottom: 18px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #f3f2f1;
}

.section-container-header-title {
  font-size: 20px;
  font-weight: 600;
}
