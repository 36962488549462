$background-color_1: #ffffff;
$color: #e1dfdd;

.grid {
  display: grid;
  grid-template-columns: 1fr 235px;
  column-gap: 5px;
}

.chart-modal {
  background-color: $background-color_1 !important;
}
.chart-modal--content {
  box-shadow: unset !important;
}

.is-waveform-layout .waveform-chart-section,
.compare-layout .ms-Stack > .trendViewDataPointsSidebar:first-child,
.is-compare-layout > div.ms-Stack:first-child:not(:only-child) {
  border-bottom: 1px solid $color;
}

.border--hidden {
  border-bottom: unset !important;
  margin-bottom: 0 !important;
}

.no-select * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.layout-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr;

  &--hidden {
    display: block !important;
  }
}

.trend-view-layout-divider {
  background: $color;
  border: none;
  height: 1px;
  margin: 15px 0;

  &--hidden {
    display: none;
  }
}

.waveform-layout .waveform-chart-section {
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.raw-data-chart-section--hidden,
.trend-view-chart-section--hidden,
.placeholder--hidden {
  display: none;
}

.raw-data-chart-section--maximized {
  border-bottom: unset !important;
  height: calc(
    100vh - 165px
  ); // 153px = 62px (header controls from raw data/trend view) + 41px (chart header + margins) + 50px (main app top blue bar)
  width: 100%;
  margin-bottom: 0;

  div[id^="root-xy-"],
  div[id^="chart-xy-"] {
    height: 100% !important;
    width: 100%;
  }

  & + br {
    display: none;
  }
}

.trend-view-chart-section--maximized {
  border-bottom: unset !important;
  height: calc(
    100vh - 130px
  ); // 153px = 62px (header controls from raw data/trend view) + 41px (chart header + margins) + 50px (main app top blue bar)
  width: 100%;
  margin-bottom: 0;

  > .ms-Stack {
    height: 100% !important;
  }

  div[id^="root-xy-"],
  div[id^="chart-xy-"] {
    height: 100% !important;
    width: 100%;
  }

  & + br {
    display: none;
  }
}
