.performance-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.performance-modal {
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 100%;
  height: 100%;

  label {
    font-size: 16px;
  }
  &__table-wrap-title {
    height: 42px;
    padding: 11px 12px;
    background: #f3f2f1;
    color: #3b3a39;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  &__table {
    height: calc(100% - 42px);
    .table-drive-load-events {
      margin: 0;
      height: 100%;
      min-height: 0;
      overflow: visible;
    }
    .ms-Viewport {
      height: 100%;
    }
    .ms-DetailsList {
      height: 100%;
      overflow: hidden;

      > div {
        height: 100%;
        overflow: auto;
      }
      .ms-DetailsList-headerWrapper {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
    .ms-DetailsList-contentWrapper {
      min-height: 0;
      height: calc(100% - 44px);
      width: 100%;
      overflow: visible;
    }

    .ms-DetailsRow {
      cursor: default;
    }
  }
}
