.waterfall-tooltip {
  position: fixed;
  background: #fff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 12px;
  z-index: 1;
  text-align: left;

  &__row {
    &:last-child {
      margin-bottom: 0;
    }

    span {
      white-space: nowrap;

      &:first-child {
        margin-right: 4px;
        font-weight: 800;
      }
    }
  }
}
