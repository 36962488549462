.comments-wrapper {
  gap: 24px;
  margin: 0px auto;
  padding: 16px 0px;
  max-width: 800px;
}

.comment {
  max-width: 680px;
  align-items: flex-end;
  align-self: flex-end;

  .date-posted {
    gap: 8px;
  }

  .dalog-tag {
    background: #2c529f;
    color: #faf9f8;
    padding: 2px;
    border-radius: 2px;
  }

  .avatar-details {
    gap: 16px;
    margin: 0px 40px 8px 0px;
    color: #000;
    font-size: 12px;
    line-height: 16px;
  }

  .comment-content {
    gap: 8px;
    flex-direction: row-reverse;
  }
  .comment-text-wrap {
    padding: 8px;
    background: #faf9f8;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.13);
    border-radius: 2px;

    .comment-text {
      white-space: pre-wrap;
      padding: 4px;
      line-height: 20px;
      gap: 8px;
    }
  }

  .document {
    margin-bottom: 4px;
    gap: 2px;
    align-items: center;

    .document-file {
      color: #3b3a39;
      font-size: 12px;
      cursor: pointer;
    }

    .document-file:hover {
      color: #0078d4;
    }
  }
}

.horizontal-line {
  border-radius: 2px;
  height: 1px;
  background: #e1dfdd;
}

.reverse {
  align-items: flex-start;
  align-self: flex-start;

  .avatar-details {
    margin: 0px 0px 8px 40px;
  }

  .comment-content {
    flex-direction: row;
  }

  .comment-text-wrap {
    background: white;
  }
}

.pointer {
  cursor: pointer;
}
