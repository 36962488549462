* {
  --color-background: #e1dfdd;
  --color-background-disabled: #f3f2f1;
  --color-selected: rgb(44, 83, 160);
  --color-hover: rgba(243, 242, 241);
  --color-active: rgba(237, 235, 233);
  --color-disabled: rgb(161, 159, 157);
}

.accordion-item-disabled,
.accordion-item {
  border: unset;
  background: transparent;
  text-align: start;
  padding: 0.4em 1em;
}

.accordion-item {
  cursor: pointer;
}

.accordion-item:hover {
  background-color: var(--color-hover);
}

.accordion-item:hover span {
  color: rgb(16, 110, 190);
}

.accordion-item:active {
  background-color: var(--color-active);
}

.accordion-item:active span {
  color: rgb(235, 63, 61);
}

.accordion-item-selected {
  background-color: var(--color-selected);
}

.accordion-item-selected span {
  color: white;
}

.accordion-item-disabled {
  cursor: default;
}

.accordion-content {
  background-color: var(--color-background);
  padding: 0.5em 0.5em 0.5em 1em;
}
