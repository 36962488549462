.kpi-table {
  overflow: visible;
  margin: 0px -8px;
  min-height: 460px;

  .ms-DetailsList {
    > div {
      position: relative;
      overflow: auto;
    }
  }

  .ms-DetailsList-headerWrapper {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .ms-DetailsList-contentWrapper {
    overflow: visible;
  }

  .disable-unchecked-values {
    .ms-DetailsRow:not(.is-selected) {
      pointer-events: none;
      .ms-DetailsRow-fields,
      .ms-DetailsRow-cellCheck {
        opacity: 0.5;
      }
    }
  }

  .disable-select-all-button {
    .ms-DetailsHeader-cellIsCheck {
      pointer-events: none;
      opacity: 0;
    }
  }
}
