* {
  box-sizing: border-box;
}

html {
  overflow-y: overlay;
}

body {
  background: #faf9f8;
}

.machine-cv-conversion-details-list-table
  .ms-DetailsList-contentWrapper
  .ms-List
  .ms-List-cell
  .ms-DetailsRow-fields {
  align-items: center;
}

.slider-container {
  background-color: #ffffff;
  height: 600px;
  margin: 0 16px;
  padding: 85px;
}
.slick-slider {
  height: 100%;
}
.slick-list,
.slick-track,
.slick-slide > div {
  height: 100%;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}
button.slick-prev.slick-arrow {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNyAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMTU4OTcgMTQuMzY3MkM4LjIwNzUgMTQuNDExNCA4LjI2NDI2IDE0LjQ0NTcgOC4zMjYwMyAxNC40NjhDOC4zODc3OSAxNC40OTAzIDguNDUzMzUgMTQuNTAwMiA4LjUxODk1IDE0LjQ5NzJDOC41ODQ1NiAxNC40OTQyIDguNjQ4OTMgMTQuNDc4MyA4LjcwODM4IDE0LjQ1MDRDOC43Njc4NCAxNC40MjI1IDguODIxMjIgMTQuMzgzMiA4Ljg2NTQ3IDE0LjMzNDdDOC45MDk3MyAxNC4yODYxIDguOTQzOTkgMTQuMjI5NCA4Ljk2NjMxIDE0LjE2NzZDOC45ODg2MyAxNC4xMDU4IDguOTk4NTYgMTQuMDQwMyA4Ljk5NTU0IDEzLjk3NDdDOC45OTI1MyAxMy45MDkxIDguOTc2NjIgMTMuODQ0NyA4Ljk0ODcyIDEzLjc4NTJDOC45MjA4MyAxMy43MjU4IDguODgxNSAxMy42NzI0IDguODMyOTcgMTMuNjI4MkwyLjY2NDk3IDcuOTk4MTVIMTYuNDk2QzE2LjYyODYgNy45OTgxNSAxNi43NTU4IDcuOTQ1NDcgMTYuODQ5NSA3Ljg1MTdDMTYuOTQzMyA3Ljc1Nzk0IDE2Ljk5NiA3LjYzMDc2IDE2Ljk5NiA3LjQ5ODE1QzE2Ljk5NiA3LjM2NTU0IDE2Ljk0MzMgNy4yMzgzNiAxNi44NDk1IDcuMTQ0NkMxNi43NTU4IDcuMDUwODMgMTYuNjI4NiA2Ljk5ODE1IDE2LjQ5NiA2Ljk5ODE1SDIuNjY3OTdMOC44MzI5NyAxLjM2OTE1QzguOTI1ODYgMS4yNzg1NCA4Ljk3OTg5IDEuMTU1MzcgOC45ODM2MyAxLjAyNTY2QzguOTg3MzggMC44OTU5NSA4Ljk0MDU1IDAuNzY5ODY2IDguODUzMDQgMC42NzQwNDlDOC43NjU1MyAwLjU3ODIzMiA4LjY0NDIgMC41MjAxODkgOC41MTQ2OSAwLjUxMjE4NEM4LjM4NTE3IDAuNTA0MTc4IDguMjU3NjIgMC41NDY4MzkgOC4xNTg5NyAwLjYzMTE1TDEuMjQyOTcgNi45NDUxNUMxLjE1NjE4IDcuMDI0MDUgMS4wODkyMSA3LjEyMjMxIDEuMDQ3NTEgNy4yMzE5NUMxLjAwNTgxIDcuMzQxNTkgMC45OTA1NTMgNy40NTk1MSAxLjAwMjk3IDcuNTc2MTVDMS4wMjEzOCA3Ljc1OTMyIDEuMTA2ODYgNy45MjkyMSAxLjI0Mjk3IDguMDUzMTVMOC4xNTg5NyAxNC4zNjcyWiIgZmlsbD0iIzJDNTI5RiIvPgo8L3N2Zz4K');
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: -70px;
  left: 46.5%;
  top: unset;
}
button.slick-next.slick-arrow {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguODM3IDAuNjI5OTgxQzguNzM4ODcgMC41NDA2MDMgOC42MDkyNSAwLjQ5Mzg2OCA4LjQ3NjY3IDAuNTAwMDU3QzguMzQ0MDggMC41MDYyNDUgOC4yMTkzOCAwLjU2NDg1MSA4LjEzIDAuNjYyOTgxQzguMDQwNjIgMC43NjExMTEgNy45OTM4OSAwLjg5MDcyNyA4LjAwMDA4IDEuMDIzMzJDOC4wMDYyNiAxLjE1NTkgOC4wNjQ4NyAxLjI4MDYgOC4xNjMgMS4zNjk5OEwxNC4zMyA2Ljk5OTk4SDAuNUMwLjM2NzM5MiA2Ljk5OTk4IDAuMjQwMjE1IDcuMDUyNjYgMC4xNDY0NDcgNy4xNDY0M0MwLjA1MjY3ODUgNy4yNDAyIDAgNy4zNjczNyAwIDcuNDk5OThDMCA3LjYzMjU5IDAuMDUyNjc4NSA3Ljc1OTc3IDAuMTQ2NDQ3IDcuODUzNTRDMC4yNDAyMTUgNy45NDczIDAuMzY3MzkyIDcuOTk5OTggMC41IDcuOTk5OThIMTQuMzI4TDguMTYzIDEzLjYyOEM4LjA2NSAxMy43MTc0IDguMDA2NTMgMTMuODQyIDguMDAwNDMgMTMuOTc0NUM3Ljk5NDM0IDE0LjEwNyA4LjA0MTEyIDE0LjIzNjUgOC4xMzA1IDE0LjMzNDVDOC4yMTk4OCAxNC40MzI1IDguMzQ0NTMgMTQuNDkxIDguNDc3MDIgMTQuNDk3MUM4LjYwOTUyIDE0LjUwMzEgOC43MzkgMTQuNDU2NCA4LjgzNyAxNC4zNjdMMTUuNzUzIDguMDUyOThDMTUuODMwNCA3Ljk4Mjk1IDE1Ljg5MjMgNy44OTc0NyAxNS45MzQ3IDcuODAyMDRDMTUuOTc3IDcuNzA2NjIgMTUuOTk4OSA3LjYwMzM4IDE1Ljk5ODkgNy40OTg5OEMxNS45OTg5IDcuMzk0NTggMTUuOTc3IDcuMjkxMzQgMTUuOTM0NyA3LjE5NTkyQzE1Ljg5MjMgNy4xMDA1IDE1LjgzMDQgNy4wMTUwMSAxNS43NTMgNi45NDQ5OEw4LjgzNyAwLjYyOTk4MVoiIGZpbGw9IiMyQzUyOUYiLz4KPC9zdmc+Cg==');
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: -70px;
  right: 46.5%;
  top: unset;
}
.slick-slider-image {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.slider-container .slick-slider .slick-list .slick-track .slick-slide .slider-slider-placeholder {
  height: 100%;
  object-fit: none;
  width: 100%;
  display: block;
}
