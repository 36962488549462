.Events-DataGridCell {
  &--white {
    color: #fff !important;
  }
}

.events-container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  row-gap: 1em;
}

.two-columns {
  display: flex;
  gap: 16px;

  .white-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    flex: 1;
  }
}

.events-chart-container {
  position: relative;

  .operation-chart {
    transition: all 0.2s ease-in-out;
  }

  &.no-data,
  &.loading {
    .operation-chart {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &.no-data {
    .operation-chart {
      opacity: 0;
      pointer-events: none;
    }
  }

  .no-data-message,
  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.events-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -8px -8px 0 -8px;

  &__tab {
    flex: 1;
    display: flex;

    &.active {
      a {
        background-color: #2c529f;
        border-color: #2c529f;
        color: #fff;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }

    a {
      display: flex;
      flex: 1;
      height: 42px;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-bottom: 1px solid #f3f2f1;
      text-decoration: none;
      color: #3b3a39;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.table-drive-load-events {
  box-sizing: border-box;
  background: white;
  height: 100%;
  flex-grow: 1;
  overflow: scroll;

  .fui-DataGridBody {
    overflow: auto;
    height: 400px;
  }

  .table {
    margin: 0;
  }

  .snapshot-column {
    white-space: normal;
    max-width: calc(100% - 40px);
  }

  &::-webkit-scrollbar {
    background: none;
  }
}
