.operation-command-bar {
  margin-left: 16px;
  display: flex;
  flex: 1;

  .layout-dropdown {
    height: 30px;
  }
}

.chart-hidden {
  .operation-chart {
    display: none;
  }
}

.operations-tab-content {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .operation-tab-chart {
    height: 100%;
    width: 100%;
  }
}
