.axis-picker-dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;

  .axis-picker-dropdown-item_text {
    max-width: calc(100% - 21px);
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 4px;
  }
}
