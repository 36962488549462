:root {
  --default-color: #201f1e;
  --light-color: #605e5c;
  --lighter-color: #a19f9d;
}

.overview-container {
  display: grid;
  grid-template-columns: 1fr 250px;
  padding: 13px 16px;
}

.overview-title {
  color: var(--default-color);
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 8px;
}

.overview-subtitle {
  color: var(--default-color);
  line-height: 20px;
  margin-bottom: 8px;
}

.overview-breadcrumb {
  color: var(--lighter-color);
  line-height: 16px;
  margin-bottom: 8px;
}

.overview-description {
  color: var(--light-color);
  line-height: 20px;
}
