/*
  START - CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/

@import "./fonts.scss";

$westar: #e1dfdd;
$cloud: #c8c6c4;
$tropaz: #2c529f;
$pampas: #faf9f8;

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family:
    "Open Sans",
    "Segoe UI",
    "Segoe UI Web (West European)",
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    "Helvetica Neue",
    sans-serif;
  margin: 0;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root {
  isolation: isolate;
  height: 100%;
}

.plotly-svg-fix svg {
  display: inherit !important;
}

/* END - CSS Reset */

*::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  border: 0;

  &:hover {
    border-left: 1px solid $westar;
    border-right: 1px solid $westar;
  }
}

*::-webkit-scrollbar-thumb {
  background: $cloud;
  background-clip: content-box;
  border-radius: 8px;
  border: 3px solid rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: $tropaz;
}

.sidebar-header {
  position: relative;
  background-color: $pampas;
  padding: 11.5px 8px 11.5px 16px;
  width: 305px;
  min-width: 305px;
  z-index: 9;
  min-height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    height: 80%;
    width: 1px;
    background-color: $westar;
  }
}

.sidebar-scrollbar {
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 22px);
    right: -4px;
    width: 6px;
    height: 20px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAUCAYAAACu0kzYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAoSURBVHgB7c4hFQAACAPRPVpt9M+xKqg1QCD47twB+0S6RacLF/xVDIbYCU8c1KmrAAAAAElFTkSuQmCC");
  }
}

.ws-sidebar-header {
  position: relative;
  background-color: $pampas;
  padding: 11.5px 8px 11.5px 16px;
  width: 305px;
  min-width: 305px;
  z-index: 9;
  min-height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container-lockable {
  padding: 5px;
}
