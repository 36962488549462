.chart-layout-modal-content {
  gap: 16px;

  .horizontal-line {
    border-radius: 2px;
    height: 1px;
    background: #e1dfdd;
  }

  .section {
    gap: 8px;
  }

  .section-title {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #3b3a39;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    word-wrap: break-word;
    margin-bottom: 8px;
  }

  .section-description {
    color: #605e5c;
    line-height: 20px;
  }
}
