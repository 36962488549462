.image-preview-action-buttons {
  position: fixed;
  top: 66px;
  right: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow:
    rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  padding: 4px;
  border-radius: 4px;
  z-index: 1;
  gap: 8px;
}
