.toggle-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease; /* Transition for expanding/collapsing */
  margin-top: 10;
}

.toggle-content {
  overflow: hidden;
  transition: max-height 0.5s ease; /* Transition for expanding/collapsing */
}

.collapsed {
  max-height: 10; /* Set a fixed height for collapsed state */
}

.expanded {
  max-height: 500px; /* Set to a value greater than the expected height */
}

.coralIcon {
  color: coral;
  margin-right: 8px;
}
