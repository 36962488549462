.colored-chart {
  .loading-no-data-chart-container {
    position: relative;
  }

  .loading-chart-data {
    z-index: 1;
  }

  &.no-data,
  &.loading {
    .colored-scatter-chart {
      opacity: 0.4;
    }
  }

  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .loading-chart-data {
    left: calc(50% + 10px);
  }
}

.colored-scatter-chart {
  position: relative;
  opacity: 1;
  transition: all 0.2s ease-in-out;

  .left-title,
  .bottom-title {
    position: absolute;
    color: rgb(59, 58, 57);
    text-align: right;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }

  .left-title {
    left: 0;
    top: 50%;
    transform: translateY(calc(-50% + 50px)) rotate(-90deg);
    transform-origin: 0 0;
  }

  .bottom-title {
    left: 50%;
    transform: translateX(calc(-50% + 10px));
    bottom: 2px;
  }
}
