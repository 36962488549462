* {
  --primary-color-hover: rgb(16, 110, 190);
  --primary-color-active: rgb(235, 63, 61);
  --secondary-color-hover: rgb(243, 242, 241);
  --secondary-color-active: rgb(237, 235, 233);
}

.rc-md-editor {
  width: 100%;
  height: 20em;
}

.rc-md-editor .editor-container .sec-md .input {
  background-color: var(--secondary-color-hover);
  box-shadow: none;
}

.markdown-text-container {
  box-sizing: border-box;
  text-wrap: wrap;
  padding-left: 0.5em;
  white-space: pre-line;
}

.markdown-text-container p {
  margin: 0px;
}

.markdown-text-container ul,
.markdown-text-container ol {
  padding-left: 1.5em;
}

.button {
  border: unset;
  background: transparent;
}

.rc-md-editor .rc-md-navigation .button-wrap .button:hover {
  color: var(--primary-color-hover);
  background-color: var(--secondary-color-hover);
}

.rc-md-editor .rc-md-navigation .button-wrap .button:active {
  color: var(--primary-color-active);
  background-color: var(--secondary-color-active);
}
