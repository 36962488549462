* {
  --primary-color-hover: rgba(16, 110, 190, 1);
  --primary-color-active: rgba(235, 63, 61, 1);
  --secondary-color-hover: rgba(243, 242, 241, 1);
  --secondary-color-active: rgba(237, 235, 233, 1);
}

.table-icon-button:enabled {
  color: var(--dalog-blue);
  height: 24px;
  padding: 0.3em;
}

.table-icon-button:enabled:hover {
  color: var(--primary-color-hover);
  background-color: var(--secondary-color-hover);
}

.table-icon-button:enabled:active {
  color: var(--primary-color-active);
  background-color: var(--secondary-color-active);
}

.table-action-button:enabled {
  height: 24px;
  color: var(--dalog-blue);
  font-size: 12px;
  text-align: start;
  text-decoration: underline;
  justify-content: flex-start;
}

.table-action-button:enabled:hover {
  color: var(--primary-color-hover);
}

.table-action-button:enabled:active {
  color: var(--primary-color-active);
}

.primary-button:enabled {
  border-color: var(--dalog-blue);
  background-color: var(--dalog-blue);
}

.primary-button:enabled:hover {
  border-color: var(--primary-color-hover);
  background-color: var(--primary-color-hover);
}

.primary-button:enabled:active {
  border-color: var(--primary-color-active);
  background-color: var(--primary-color-active);
}

.secondary-button:enabled {
  border: unset;
  background: transparent;
}

.secondary-button:enabled:hover {
  color: var(--primary-color-hover);
  background-color: var(--secondary-color-hover);
}

.secondary-button:enabled:active {
  color: var(--primary-color-active);
  background-color: var(--secondary-color-active);
}
