.machine-cv-performance {
  .trend-view-section {
    margin: 0;
    box-shadow: none;
    padding: 0;
  }

  .trend-view-plot-container-no-data,
  .trend-view-plot-container-loading {
    height: calc(50vh - 160px) !important;
    min-height: 480px;
    box-shadow: none !important;
    margin-bottom: 0 !important;
  }

  .trend-view-chart-section--maximized{
    height: 100% !important;
  }
}
