@import "./connectionDetailsDialog.css";
@import "./buttons.css";
@import "./texts.css";

* {
  margin: 0px;
  padding: 0px;
  --dalog-blue: rgb(44, 83, 160);
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.box-shadow-panel {
  box-shadow:
    0px 3.200000047683716px 7.199999809265137px 0px rgba(0, 0, 0, 0.13),
    0px 0.6000000238418579px 1.7999999523162842px 0px rgba(0, 0, 0, 0.1);
}

.vpn-connections-container {
  padding: 8px;
  background: transparent;
  height: 75vh;
}

.default-button:enabled {
  border: unset;
  background: transparent;
}
