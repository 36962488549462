$pampas: #faf9f8;
$tropaz: #2c529f;
$desert_storm: #f3f2f1;
$ebb: #edebe9;
$tuatara: #323130;

.am5exporting.am5exporting-menu {
  padding: 0;
  display: inline-block;
  position: relative !important;
  &:hover {
    background: $pampas !important;
  }
  path {
    fill: $tropaz !important;
  }
}

.am5exporting.am5exporting-icon {
  background: transparent !important;
  height: auto;
  margin: 0;
  opacity: 1 !important;
  padding: 0 !important;
  position: unset !important;
  width: auto;
}

.amcharts-export-button {
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  display: flex;
  font-size: 14px;
  padding: 10px;
  user-select: none;
  &:hover {
    background: $pampas !important;
  }

  &--no-label {
    .amcharts-export-button__icon {
      margin-right: 0;
    }

    .amcharts-export-button__text {
      display: none;
    }
  }
}

.am5exporting.am5exporting-menu-open {
  > .am5exporting.am5exporting-list {
    background: white !important;
    border-radius: 0;
    border: 0;
    box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
    display: table;
    left: 0;
    margin-left: 0;
    min-width: 150px;
    padding: 0 !important;
    position: absolute !important;
    width: auto;
    z-index: 100;

    .am5exporting-item {
      a {
        padding: 10px 15px !important;
        white-space: nowrap !important;
        font-weight: 400;
        font-size: 12px;
      }
      &:not(:first-child) {
        a {
          &:hover {
            background: $desert_storm !important;
          }
        }
      }
    }

    .am5exporting-item.am5exporting-type-separator {
      border-color: $ebb !important;
    }
  }
}

.amcharts-export-button__icon {
  margin: 2px 10px 0 0;

  svg {
    min-height: 16px;
    min-width: 16px;
  }
}

.amcharts-export-button__text {
  color: $tuatara;
  font-weight: 400;
  white-space: nowrap;
}

.chart-tooltip {
  background: white;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 12px;

  .chart-tooltip__label {
    display: flex;
    align-items: center;
  }

  .chart-tooltip__content {
    display: flex;
    align-items: center;
    gap: 2px;

    img {
      margin: 3px 0 0 3px;
    }
  }
}

.legend-container {
  width: 100%;
}

.reference-tooltip-shape-icon {
  background: #c7e0f4;
  width: 12px;
  height: 12px;
  display: block;
  margin: 3px 3px 0 0;
}

.breadcrumb-hidden {
  display: none;
}
