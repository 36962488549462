* {
  box-sizing: border-box;
}

:root {
  --dalog-blue: rgb(44, 82, 159);
}

body,
html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

p {
  color: #5c5c5c;
}

input > i {
  color: var(--dalog-blue);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-size-m {
  font-size: 20px;
}

.font-size-l {
  font-size: 23px;
}

.font-size-xl {
  font-size: 32px;
}

.font-bold {
  font-weight: bold;
}

.color-blue-1 {
  color: var(--dalog-blue);
}

.color-gray-5a {
  color: #5c5c5c;
}

h3 {
  color: var(--dalog-blue);
  font-weight: bold;
}

/* LOGIN */

.login-form {
  text-align: center;
  background: var(--dalog-blue);
  max-width: 450px;
  border-radius: 8px;
  margin: 100px auto 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.login-form .titulo,
.form-change-password .titulo {
  color: var(--dalog-blue);
  padding: 10px;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 30px;
}

.btn {
  font-size: 14px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  padding: 15px 50px;
  background-color: #ec3a3d;
  font-style: normal;
  border-radius: 8px;
  color: #fff;
  border: medium;
  font-weight: bold;
}

.input {
  font-size: 14px;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #c5def8;
  font-style: normal;
  color: #999;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 1px 1px 5px #666;
}

body.login {
  background-size: cover;
  background-image: url(./assets/images/background.jpg);
  background-position: center center;
}

body.login .logo {
  padding: 20px;
  background-color: #fff;
  border-bottom: 8px solid var(--dalog-blue);
  text-align: center;
}

body.login .links {
  margin-bottom: 20px;
}

body.login .links div {
  width: 48%;
  display: inline-block;
}

body.login .links A {
  color: var(--dalog-blue);
}

/* MENÚ */
.menubar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 60px;
  background-color: var(--dalog-blue);
}

.item-menu {
  font-size: 16px;
  text-align: center;
  position: relative;
  cursor: pointer;
  text-align: center;
  color: #fff;
  padding: 10px;
  font-weight: bold;
}

.item-menu:hover {
  background-color: var(--dalog-blue);
}

.others-btn {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
}

.submenu {
  position: absolute;
  left: 100%;
  bottom: 0;
  width: 100px;
  visibility: hidden;
  background: var(--dalog-blue);
  /* adornos */
  transition: all 0.3s;
  opacity: 0;
  transform: translateY(-10px);
}

.item-menu:hover .submenu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.icon-menu {
  width: 30px;
  height: 30px;
}

/* INTERNAL */
.content {
  margin-left: 60px;
}

.app-header {
  padding: 20px;
  background-color: #fff;
  border-bottom: 8px solid var(--dalog-blue);
  display: flex;
}

.app-header .logo {
  max-width: 220px;
  flex: 0 0 200px;
}

.app-header .app-header-title-container {
  flex-grow: 1;
}

.form-change-password {
  width: 450px;
  margin: 50px auto 0;
}

/* TWO FACTOR AUTHENTICATION */

.btn-form-change-password {
  font-size: 14px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  padding: 3% 12%;
  background-color: #ec3a3d;
  font-style: normal;
  border-radius: 8px;
  color: #fff;
  border: medium;
  font-weight: bold;
  margin: 10px;
  float: left;
  white-space: normal;
  width: 40%;
}

.align-side {
  float: right;
}

.content-box {
  margin: 0 auto;
  width: 25%;
}

.content-box-button {
  width: 60%;
  margin: 0 auto;
  padding-left: 120px;
}

@media only screen and (max-width: 740px) {
  .content-box-button {
    width: 100%;
    margin: 0 auto;
    padding-left: none;
  }

  .align-side {
    float: none;
  }

  .content-box {
    margin: 0 auto;
    width: 80%;
  }
}

/* CONFIGURE AUTHENTICATOR */
.content-box-authenticator {
  margin: 0 auto;
  width: 80%;
}

.input-configure {
  font-size: 14px;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #c5def8;
  font-style: normal;
  color: #999;
  width: 35%;
  margin-bottom: 20px;
  box-shadow: 1px 1px 5px #666;
}

/* RESET PASSWORD */

.password-form {
  text-align: center;
  background: white;
  max-width: 550px;
  border-radius: 8px;
  margin: 100px auto 0 auto;
  padding: 60px 80px 60px 80px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

body.reset-password {
  background-size: cover;
  background-image: url(./assets/images/background2.png);
  background-position: center center;
}

body.reset-password .logo {
  padding: 20px;
  background-color: #fff;
  border-bottom: 8px solid var(--dalog-blue);
  text-align: center;
}

body.reset-password .links {
  margin-bottom: 20px;
}

body.reset-password .links div {
  width: 48%;
  display: inline-block;
}

body.reset-password .links A {
  color: var(--dalog-blue);
}

/* SENT PASSWORD */

.password-sent-form {
  text-align: center;
  background: white;
  max-width: 550px;
  border-radius: 8px;
  margin: 100px auto 0 auto;
  padding: 100px 80px 100px 80px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

/* Collapsible component */

.Collapsible__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}

.report-style-class-detaills {
  position: relative;
  width: 100vw;
  height: calc(100vw * 0.5);
  max-height: 70vh;
}
.report-style-class-view {
  position: relative;
  width: 60vw;
  height: calc(100vw * 0.3);
  max-height: 90vh;
}

.report-style-class-show {
  position: relative;
  width: 100vw;
  height: calc(100vw * 0.5);
  max-height: 95vh;
}

.box-shadow-panel {
  box-shadow: 0px 3.200000047683716px 7.199999809265137px 0px
      rgba(0, 0, 0, 0.13),
    0px 0.6000000238418579px 1.7999999523162842px 0px rgba(0, 0, 0, 0.1);
}

.toast-container {
  --toastify-color-light: var(--dalog-blue);
  --toastify-toast-width: 24em;
}

/* Area title */
.area-title {
  color: var(--text-black);
  font-size: 18px;
  font-weight: 600;
  border-right: 1px solid rgb(225, 223, 221);
  padding-right: 1.25em;
  margin-left: 1em;
}
