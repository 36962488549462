.Compact-DataGridCell {
  font-weight: 600;
  padding: 11px 12px;
}

.MachineCV-Summary-DataGridCell {
  color: #fff !important;
  font-weight: bold;
  justify-content: center;
}

.Home-DataGridCell {
  color: #3b3a39 !important;

  &--Ok {
    background: #66cd7d;
  }

  &--Indication {
    background: #ffcc00;
  }

  &--Alert {
    background: #ff7e0d;
  }

  &--Danger {
    background: #f63f4d;
  }

  &--NotInstalled {
    background: #5a71a9;
  }

  &--NoStatus {
    background: #b3b3b3;
  }

  &--Faulty {
    background: #c7b8e7;
  }

  &--Up-to-date {
    background: #66cd7d;
  }

  &--At-risk {
    background: #ffcc00;
  }

  &--Late {
    background: #ff7e0d;
  }
}

.machines-container {
  .ms-DetailsList {
    margin-top: 0;
  }

  .machine-conditions-machineName {
    .bookmark-button {
      min-width: unset !important;
      height: auto !important;
    }
    .ms-Button {
      position: absolute !important;
      padding: 0 0 0 12px;
      min-height: auto;
    }

    .ms-DetailsRow-cell {
      padding-left: 35px;
    }
  }
}

.MachineCard__wrapper {
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  transition: 0.05s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: initial;
  padding: 16px 16px 24px 16px;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
    box-shadow:
      0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
      0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  }

  .MachineCard__content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    padding: 0;
    gap: 8px;
    margin-bottom: 24px;

    .MachineCard__icon {
      position: initial;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 24px;

      svg {
        width: 26px;
        height: auto;
      }

      .no-images {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-top: 16px;
        width: 70%;
      }
    }

    .MachineCard__data {
      display: flex;
      flex-direction: column;

      .MachineCard__logo {
        svg {
          width: 105px;
          height: auto;
        }
      }

      .MachineCard__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
      }

      .MachineCard__subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
        padding-top: 8px;
      }

      .MachineCard__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
      }
    }
  }

  .MachineCard__buttons {
    display: flex;
    justify-content: space-between;
  }
}
