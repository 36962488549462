.cluster-list {
  position: relative;
  height: 100%;
  width: 100%;

  .condition-pill {
    font-size: 10px;
    color: #3b3a39;
    border-radius: 1024px;
    padding: 3px 8px;
  }

  .cluster-list-machine {
    display: block;
    padding: 8px;
    color: #3b3a39;
    text-decoration: none;

    .condition-dot {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }

  .project-name {
    padding: 8px;
    color: #2c529f;
    font-weight: 600;
  }
}
