.panel-card {
  min-height: 358px;
  padding: 24px;
  background: #fff;

  .machines-number {
    font-size: 40px;
    font-weight: 700;
    line-height: 22px;
  }

  .legend-circle {
    cursor: pointer;
    border-radius: 50%;
    display: block;
    height: 20px;
    width: 20px;
  }
}
