.titleContainer {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;

  .pencilEdit {
    margin-left: 10px;
    cursor: pointer;
  }
}

.taskDescription {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 48px;
  overflow: auto;

  .description {
    margin-bottom: 30px;
    overflow-wrap: anywhere;
  }
}

.comment-container {
  display: flex;
  height: fit-content;

  .comment-avatar {
    display: flex;
    flex-direction: column;
    min-width: 150px;
    max-width: 200px;
    align-items: center;
    justify-content: center;

    .fui-Avatar {
      align-self: center;
    }

    .avatar-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;

      .title {
        font-size: 12px;
        opacity: 0.6;
      }

      .created {
        font-size: 10px;
        opacity: 0.6;
      }
    }
  }

  .comment-text-container {
    display: flex;
    flex-direction: column;
    overflow-wrap: anywhere;
    width: 100%;

    .comment-text {
      margin-bottom: 10px;
    }

    .attachments {
      font-weight: 600;
    }

    .comment-documents {
      display: flex;
      flex-direction: column;
    }
  }
}

.tags-comment-container {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin-bottom: 3px;
}

.tags-dialog-container {
  margin-top: 20px;
  max-height: 300px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  overflow: scroll;
}

.tag-container {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: #f3f2f1;

  .tag {
    font-size: 12px;
  }
}

.filter-title {
  display: flex;
  height: 24px;

  .filter-element {
    display: flex;
    align-items: center;
    background: #f3f2f1;
    margin: 0 5px;
    border-radius: 999px;

    .filter-delete:hover {
      background: initial;
    }
  }
}
