.waterfall-footer {
  display: flex;
  align-items: center;

  &__period {
    margin-left: auto;
    color: #3b3a39;
    font-size: 12px;
    font-weight: 600;
  }

  &__breadcrumb {
    margin-right: 30px;
    max-width: 80%;
  }
}
