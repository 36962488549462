.message-item {
  padding: 16px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 8px;

  .avatar-active {
    background-color: #2c529f;
  }
  .role-active {
    background-color: #d0e0e0;
  }
  .message-active {
    color: #000;
  }

  .userSection {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
  }

  .userInfo {
    display: flex;
    align-items: center;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #0078d4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
  .initials {
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  .nameDate {
    display: flex;
    flex-direction: column;
  }
  .name {
    font-weight: 600;
    font-size: 14px;
  }
  .date {
    font-size: 12px;
    color: #666;
  }
  .role {
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-size: 12px;
  }

  .message {
    font-size: 14px;
    line-height: 20px;
    color: #333;
  }
}

.container-active {
  background-color: #e8f4ff;
}
