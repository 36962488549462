.details-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0.3em;
}

.details-text {
  flex: 33%;
}

.details-value {
  flex: 66%;
  align-self: center;
}
