.pdfPreview canvas {
  display: flex;
  margin: auto;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.file-name {
  color: #0078d4;
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}

.preview-container {
  max-height: 90vh;
  overflow: scroll;
}

.file-container {
  display: flex;
  gap: 4px;
  width: auto;
  min-width: 120px;
  max-width: 280px;
  padding: 4px 4px;
  margin: 1px 0;
  background-color: #f8f8f8;
  border-radius: 2px;
  border: 1px solid #e0e0e0;

  .file-name {
    display: block;
    width: 100%;
    line-height: 1;
    padding: 2px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    font-size: 12px;
  }
}

.files-list {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 280px;
  gap: 0;
}
