.box-shadow-panel {
  -webkit-box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.13);
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.13);
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.13);
}

.machine-cv-overview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 16px;
  margin: 16px 0 16px 0;
}

.machine-cv-card {
  width: 100%;
  background-color: #fff;
  padding: 16px;
  margin: 2px;
  border-radius: 2px;
}

.machine-cv-card-selected {
  background-color: #deecf9;
}

.machine-cv-card-condition-span {
  color: white;
  padding: 0.25em 0.75em;
  min-width: 4em;
  border-radius: 2px;
  box-shadow:
    rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
}

.machine-cv-card-panel-left {
  width: 44%;
  padding: 8px 0px 0px 8px;
}

.machine-cv-card-image-container {
  width: 100%;
  cursor: pointer;
  margin-top: 8px;
  flex-grow: 1;
}

.machine-cv-card-image {
  max-height: 250px;
  max-width: 100%;
  box-sizing: border-box;
  object-fit: contain;
}

.machine-cv-card-panel-right {
  width: 54%;
  align-self: stretch;
}

.toggle-overview-button {
  color: #fff;
}

.router-link {
  color: #2c529f;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.header {
  background: #2c529f;
  padding: 0px 8px;
  cursor: pointer;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 400px 1fr;
  border-radius: 2px;

  &--open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 200px 1fr;
  }
}

.header-items {
  justify-content: center;

  * {
    color: #ffffff;
  }

  @media screen and (max-width: 768px) {
    &__title {
      display: none;
    }
  }
}

.corporation-header {
  & ~ .company-section + .company-section {
    margin-top: 30px;
  }
}

.company-section__last-item {
  margin-bottom: 30px;
}

.actions {
  display: flex;
}
